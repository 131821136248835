import { useTranslate } from "@lobby/ocb-intl";
import { Link, useSearch } from "@tanstack/react-router";

import { Lobby } from "@entities/lobby";
import { CategoryIcon } from "@features/category/category-icon";
import { LanguageSelector } from "@features/language-selector";
import { MobileMenuButton } from "@features/mobile-menu-button";
import { PlayerInfo } from "@features/player";
import { ThemeSwitch } from "@features/theme-switch";
import { useAuth, useModal } from "@shared/lib";
import { SVGIcon } from "@shared/ui";
import { Button } from "@shared/ui/button";
import { Logo } from "@shared/ui/logo";

import { useHeaderCategories } from "../lib";

export function Header() {
  const lobby = Lobby.useLobby();
  const headerCategories = useHeaderCategories();

  const { $t } = useTranslate();

  const { open: openLogInModal } = useModal("log-in");
  const categoryId = useSearch({ strict: false, select: ({ cid }) => cid });
  const { isAuth } = useAuth();

  // const hasContactsButton = lobby.data?.buttons?.contacts?.enabled;

  return (
    <header className="lg:h-17.5 bg-mercury dark:bg-outer-space sticky top-0 z-10">
      <div className="lg:max-w-screen-3xl lg:px-5 flex mobile-only:flex-col justify-between mx-auto h-full">
        <nav className="lg:items-center lg:h-full lg:overflow-x-auto scrollbar-thin flex mobile-only:order-2">
          <div className="mobile-only:hidden">
            <Link to="/">
              <Logo className="h-11" />
            </Link>
          </div>
          <ul className="lg:h-full lg:items-stretch lg:px-5 flex font-bold uppercase scrollbar-thin mobile-only:overflow-x-auto mobile-only:text-xs mobile-only:w-full">
            <li className="mobile-only:flex-1 mobile-only:h-8">
              <Link
                className="lg:gap-2.5 lg:px-7.5 px-3 flex items-center gap-1.5 mobile-only:justify-center h-full"
                activeOptions={{ exact: headerCategories.some(({ id }) => id === categoryId) }}
                activeProps={{
                  className: "bg-keppel text-white dark:bg-bright-gray dark:text-java",
                }}
                to="/"
              >
                <SVGIcon name="cardHearts" className="lg:size-6 size-5" />
                {$t({ defaultMessage: "Casino" })}
              </Link>
            </li>
            <li className="mobile-only:flex-1 mobile-only:h-8">
              <Link
                className="lg:gap-2.5 lg:px-7.5 px-3 flex items-center gap-1.5 mobile-only:justify-center h-full"
                activeProps={{
                  className: "bg-keppel text-white dark:bg-bright-gray dark:text-java",
                }}
                to="/game/$gameId"
                params={{
                  gameId: "sport",
                }}
              >
                <SVGIcon name="americanFootball" className="lg:size-6 size-5" />
                {$t({ defaultMessage: "Sport" })}
              </Link>
            </li>
            {headerCategories.map(({ id, name }) => (
              <li className="mobile-only:flex-1 mobile-only:h-8" key={id}>
                <Link
                  className="lg:gap-2.5 lg:px-7.5 px-3 flex items-center gap-1.5 mobile-only:justify-center h-full"
                  activeProps={{
                    className: "bg-keppel text-white dark:bg-bright-gray dark:text-java",
                  }}
                  to="/"
                  search={{ cid: id }}
                >
                  <CategoryIcon className="lg:*:size-6 *:size-5" id={id} />
                  {name}
                </Link>
              </li>
            ))}
            {isAuth && (
              <li className="mobile-only:flex-1 mobile-only:h-8">
                <Link
                  className="lg:gap-2.5 lg:px-7.5 px-3 flex items-center gap-1.5 mobile-only:justify-center h-full"
                  search={(prev) => ({
                    ...prev,
                    modal: "profile",
                    profileSection: "sportBetsHistory",
                  })}
                >
                  <SVGIcon name="trophy" className="lg:size-6 size-5" />
                  {$t({ defaultMessage: "Sport Bets history" })}
                </Link>
              </li>
            )}
            {/* {hasContactsButton && (
              <li className="mobile-only:flex-1 mobile-only:h-8">
                <Link
                  className="lg:gap-2.5 lg:px-7.5 px-3 flex items-center gap-1.5 mobile-only:justify-center h-full"
                  search={(prev) => ({ ...prev, modal: "contacts" })}
                  replace
                >
                  <SVGIcon name="headset" className="lg:size-6 size-5" />
                  {$t({ defaultMessage: "Contacts" })}
                </Link>
              </li>
            )} */}
          </ul>
        </nav>
        <div className="flex items-center mobile-only:justify-between lg:gap-5 lg:h-full mobile-only:p-3 mobile-only:order-1">
          <div className="flex items-center gap-2.5 lg:hidden">
            <MobileMenuButton />
            <Link to="/">
              <Logo className="h-7 w-36" />
            </Link>
          </div>
          <LanguageSelector className="mobile-only:hidden" />
          <ThemeSwitch className="mobile-only:hidden" />
          <div className="flex items-center gap-5 h-full lg:contents">
            {isAuth ? (
              <PlayerInfo />
            ) : (
              <Button onClick={openLogInModal}>{$t({ defaultMessage: "sign in" })}</Button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
