import { useTranslate } from "@lobby/ocb-intl";

import { LanguageSelector } from "@features/language-selector";
import { ThemeSwitch } from "@features/theme-switch";
import { useModal } from "@shared/lib";
import { Button } from "@shared/ui";
import { Modal } from "@shared/ui/modal";

import { PasswordForgotForm } from "./components";

export function PasswordForgotModal({ isStatic = false }: { isStatic?: boolean }) {
  const { isOpen, close } = useModal("password-forgot");

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={isStatic ? undefined : close} />
      <PasswordForgotModalComponent />
    </Modal>
  );
}

function PasswordForgotModalComponent() {
  const { open: openLogInModal } = useModal("log-in");

  const { $t } = useTranslate();

  return (
    <Modal.Panel className="mobile-only:min-h-full w-full border-keppel bg-mercury p-5 lg:top-17 lg:w-[28.75rem] lg:rounded-rounded lg:border-1 dark:border-shark dark:bg-ebony-clay">
      <div className="flex-c-sb gap-5">
        <div className="font-bold text-lg uppercase lg:text-xl">
          {$t({ defaultMessage: "Forgot your password?" })}
        </div>
        <Button onClick={openLogInModal}> {$t({ defaultMessage: "Log in" })}</Button>
      </div>

      <div className="mt-5 mobile-only:mb-10">
        <PasswordForgotForm />
      </div>

      <div className="mt-auto flex items-center justify-between lg:mt-20">
        <LanguageSelector orientation="top" />
        <ThemeSwitch />
      </div>
    </Modal.Panel>
  );
}
